<template>
  <div class="about">

    statut assistance : {{ statuttracker ? 'actif' : 'arrêté' }}
    <a href="#" v-if="!statuttracker" @click.prevent="activeTracker()" class="btn btn-block btn-hero-warning">Activer assistance</a>
    <a href="#" v-if="statuttracker" @click.prevent="stopTracker()" class="btn btn-block btn-hero-warning">Stop assistance</a>

    
    <a href="#" @click.prevent="pushAllIntervention()" class="btn btn-block btn-hero-success">Push inter</a>
    <a href="#" @click.prevent="pushAllPictures()" class="btn btn-block btn-hero-success">Push pictures</a>
    <br>
    <a href="#" @click.prevent="refreshconfig()" class="btn btn-block btn-hero-success">Refresh Config</a>
    <br>
    <br>
    <a href="#" @click.prevent="resetforms()" class="btn btn-block btn-hero-info">Reset forms</a>
    <a href="#" @click.prevent="refreshevents()" class="btn btn-block btn-hero-info">Reset events</a>
    <a href="#" @click.prevent="resetinter()" class="btn btn-block btn-hero-info">Reset inter</a>
    <br>
    <p>Token : {{getToken}} <a href="#" @click.prevent="logout" class="btn btn-block btn-hero-info">Reset token</a></p>
    <p>Nb inter à synchro : {{tosyncsInter.length}}</p>
    
    <p>Nb photo : </p>
    <ul>
      <li v-for="nameofpicutues in getAll()">
        <ul>
          <li v-for="picture in nameofpicutues">
              {{picture}} <a href="#"  class="btn btn-hero-danger" @click.prevent="deletepicture(picture)">X</a>
          </li>
        </ul>
      </li>
    </ul>
    <br>
    <p>Inter : </p>
    <ul>
      <li v-for="inter in getAllInter">
          {{inter}} <a href="#" class="btn btn-hero-danger" @click.prevent="deleteInter(inter.uid)">X</a>
      </li>
    </ul>

    <br>
    <br>
    <textarea cols="50" rows="5">{{getRegisterToken}}</textarea>
    <a href="#" @click.prevent="resetRegisterToken()" class="btn btn-hero-success">Reset Token Notitfication</a>

<br>
<br>
    Serveur : {{ urlapi }}
  </div>
</template>


<script>

  import { mapActions, mapGetters } from 'vuex'
	import {tracker} from '@/services/tracker';


  export default {
    name: 'logs',
    data: function(){
      return {
        statuttracker: false
      }
    },
    methods: {
      ...mapActions('forms', {'resetforms': 'reset'}),
      ...mapActions('events', {'refreshevents': 'refresh'}),
      ...mapActions('interventions', {'resetinter': 'reset', 'pushAllIntervention': 'pushAll', 'deleteInter': 'delete'}),
      ...mapActions('pictures', {'pushAllPictures': 'sync', 'deletepicture': 'delete'}),
      ...mapActions('login', ['logout']),
      ...mapActions('config', { 'refreshconfig': 'refresh', 'resetRegisterToken':'resetRegisterToken'}),
      activeTracker(){
				tracker.start({
          userID: this.config.user.uid,
					metadata: {
						env: process.env.NODE_ENV
					}
				});
        this.statuttracker = tracker.isActive();
      },
      stopTracker(){
				tracker.stop();
        this.statuttracker = tracker.isActive();
      }
    },
    computed: {
      // mix the getters into computed with object spread operator
      ...mapGetters('login',[
        'getToken',
      ]),
      ...mapGetters('interventions',{'getAllInter': 'getAll', 'tosyncsInter': 'tosyncs'}),
      ...mapGetters('pictures',[
        'getAll',
      ]),
      ...mapGetters('config',{'getRegisterToken':'getRegisterToken', 'config': "getConfig"}),
      urlapi(){
        return process.env.VUE_APP_EKOAPI;
      },
    },
    created(){
      this.$store.dispatch('pictures/loadall');
      this.statuttracker = tracker.isActive();

    },
  }
</script>
